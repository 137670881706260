import IMenuItem from "../../../../../model/interface/ui/IMenuItem";
import React from "react";
import MenuItemLink from "./MenuItemLink";
import {History} from "history";

const MenuGroupBuilder = {
    buildChildren: (items: IMenuItem[], parent: string | null, onClick: (item: IMenuItem, parent?: boolean) => void, history: History) => {
        const roots = parent ? items.filter(value => value.parent && value.parent === parent)
            : items.filter(value => !value.parent)
        return roots
            .sort((a, b) => (a.weight > b.weight) ? 1 : ((b.weight > a.weight) ? -1 : 0))
            .map((item) => {
                    return <MenuItemLink items={items} key={item.uuid} item={item} onItemCLick={onClick}/>
                }
            )
    }
}

export default MenuGroupBuilder