import React from "react";
import {Button as ButtonAntd, ButtonProps} from "antd";
import {ButtonType} from "antd/lib/button/button";
import IState from "../../../model/interface/dataStorage/workflow/IState";
import {ButtonSize} from "antd/es/button";
import {connect, Provider} from "react-redux";
import store, {IAppState} from "../../../redux/store";
import IButtonType from "../../../model/interface/ui/IButtonType";
import selectors from "../../../redux/selectors";

export type ButtonTypes = ButtonType | 'info' | 'success' | 'warning' | 'special' | 'danger' | 'orange' | string

export type ButtonSizes = ButtonSize | 'extraSmall'


export interface IButtonProps extends Omit<ButtonProps, 'type' | 'size'> {
    type?: ButtonTypes
    size?: ButtonSizes
    inverse?: boolean
    buttonTypes: IButtonType[]
    previewType?: IButtonType
    isDark: () => boolean
    isCustomDark?:() => boolean
}

class Button extends React.Component<IButtonProps, IState> {

    getClass(type: ButtonTypes) {
        switch (type) {
            case "info":
                return 'ant-btn-info'
            case "warning":
                return 'ant-btn-warning'
            case "success":
                return 'ant-btn-success'
            case "special":
                return 'ant-btn-special'
        }
    }

    render() {
        let {type, inverse, className, size, buttonTypes, style, shape, previewType, isDark, isCustomDark, ...rest} = this.props
        if (type && ['info', 'success', 'warning', 'special'].includes(type)) {
            className = this.getClass(type) + ' ' + (className || '')
            type = 'default'
        }
        const customType = previewType || buttonTypes.find(t => t.uuid === type)
        if (customType){
            if (isCustomDark ? isCustomDark() : isDark()){
                const color = style?.color || customType.textColor || customType.textColorDark
                style = {...style, background: style?.background || customType.colorDark || customType.color, color: color}
            } else {
                const color = style?.backgroundColor || customType.textColor
                style = {...style, background: style?.background || customType.color, color: color}
            }
            shape = customType.shape || shape
            className += ' custom'
        }

        if(inverse) {
            className += ' btn-inverse'
        }

        if (size === 'extraSmall'){
            className += ' ant-btn-xs'

        }

        const finalSize: ButtonSize = size === 'extraSmall' ? undefined : size


        return (
            <Provider store={store}>
            <ButtonAntd {...rest} shape={shape} style={style} size={finalSize} className={className}
                        type={type as ButtonType}/>
            </Provider>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        buttonTypes: state.setup.buttonTypes,
        isDark: () => selectors.theme.isDark(state)
    }
}

export default connect(mapStateToProps)(Button)