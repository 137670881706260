import {Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase from "./IFilterBase";
import FilterDropDown from "./FilterDropDown";
import Choose from "./input/Choose";

interface IFilterChoose extends IFilterBase {
    setSearch: { (search: string, setSelectedKeys: any): void },
}

const ChooseFilter: IFilterChoose = {
    handleSearch(_setSelectedKeys, selectedKeys, confirm, setValue) {
        setValue(selectedKeys)
        confirm();
    },

    handleReset(clearFilters, setValue) {
        clearFilters();
        setValue([])
    },

    setSearch(search: string, setSelectedKeys: any) {
        setSelectedKeys([{search}])
    },

    build(item, field, state, setValue): IFilterFunctions {
        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                        <Choose autoFocus={true} selected={selectedKeys} field={field} item={item}
                                onChange={value => setSelectedKeys(value)}/>
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered) => {
                let filteredBy: JSX.Element = <>
                    {'Filtrováno: '}
                    {state[0] && (Array.isArray(state[0].label) ? state[0].label.map((l, index) => (<>
                        {index > 0 && ' ,'} {l}
                    </>)) : state[0].label)}
                </>
                return (
                    <Tooltip title={filtered ? filteredBy : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            }
        }
    }
}

export default ChooseFilter