import React from "react";
import {Alert, Col, Row, Spin, Tooltip, Typography} from "antd";
import moment, {Moment} from "moment";
import {connect} from "react-redux";
import {CalendarOutlined, CaretRightOutlined, LoadingOutlined, MessageOutlined} from "@ant-design/icons";
import {IWorkloadSharedProps} from "../Workload";
import IUser from "../../../../../model/interface/security/IUser";
import IEmployee from "../../../../../model/interface/company/IEmployee";
import IEntry from "../../../../../model/interface/company/workload/IEntry";
import IPlan from "../../../../../model/interface/company/workload/IPlan";
import IType from "../../../../../model/interface/company/workload/IType";
import IFreeDay from "../../../../../model/interface/company/workload/IFreeDay";
import selectors from "../../../../../redux/selectors";
import {IAppState} from "../../../../../redux/store";
import {MomentBuilder} from "../../../../../utils/MomentBuilder";
import RequestFormModal from "../../vacation/partials/RequestFormModal";
import {DATE_FORMAT_HH_mm} from "../../../../../model/constants/DateConstant";
import CurrentPlans from "./CurrentPlans";
import Button from "../../../../shared/button/Button";
import EntryCreateButton from "../entry/EntryCreateButton";
import EntriesService from "../../../../../model/service/company/workload/EntriesService";
import Utils from "../../../../../utils";
import NoteModal from "../components/NoteModal";
import INote from "../../../../../model/interface/company/workload/INote";
import IReport from "../../../../../model/interface/company/workload/IReport";

interface IProps extends IWorkloadSharedProps {
    user: IUser,
    standAlone?: boolean,
    reload?: number
    getText: (code: string) => string
    entries: IEntry[]
    manageEntry?: (entry: IEntry, action: 'create' | 'update') => void,
    loading?: boolean
    showPlanRequest?: boolean
    onNewNote?: (note: INote) => void
    date?: Moment
    report?: IReport
    types: IType[]
}

interface IState {
    employee?: IEmployee,
    currentTime: Moment,
    plan?: IPlan,
    freeDays: IFreeDay[]
    noteModal?: boolean
}

class Start extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            freeDays: [],
            currentTime: moment(),
            employee: props.user.employees[0]
        }
    }

    static defaultProps = {
        standAlone: true,
        showPlanRequest: true
    }

    updateTime = () => {
        if (this.state.currentTime.minutes() !== moment().minutes()) {
            this.setState({currentTime: moment()})
        }
    }

    interval = setInterval(this.updateTime, 1000);

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    cancelPlan = () => {
        this.setState({plan: undefined})
    }

    createPlan = (defaultData?: any) => {
        this.setState(state => ({
            plan: {
                uuid: Utils.uuid(),
                beginAt: state.currentTime,
                endAt: state.currentTime,
                type: null,
                state: 'new'
                , ...defaultData
            } as IPlan
        }))
    }

    createNote = (close: boolean = false, note?: INote) => {
        const {onNewNote} = this.props
        this.setState({noteModal: close ? undefined : true})
        note && onNewNote?.(note)
    }

    render() {
        const {currentTime, plan, noteModal} = this.state

        const {user, getText, manageEntry, entries, loading, showPlanRequest, date, report, types} = this.props
        const employee = user.employees[0]
        const obligation = employee?.obligation

        if (!obligation) {
            return 'No obligation found for this employee'
        }

        const editableEntries = EntriesService.getInstance().getEditableEntries(entries, report)
        const currentSession = editableEntries.find(a => !a.endAt)
        const startOverlap = entries.find(a => a.uuid !== currentSession?.uuid &&
            currentTime.isBetween(MomentBuilder.build(a.startAt), MomentBuilder.build(a.endAt || currentTime.clone().endOf('d')), 'm', '()'))

        let greeting = this.getGreeting(currentTime, getText);

        const header = <div>
            <Row className={'flex-column'} justify={"center"} align={"middle"}>
                <Typography.Text className={'font-size-md'}>
                    {greeting}
                </Typography.Text>
                <Typography.Title className={'my-2'}>{currentTime.format('HH:mm')}</Typography.Title>
                <Row justify={"center"} wrap={false} className={'w-100 mb-3'} gutter={[12, 12]}>
                    <Col flex={'1 1'}/>
                    <Col>
                        <EntryCreateButton disabled={!!currentSession || !!startOverlap || !report?._permissions?.['edit']}
                            employee={employee.uuid} date={currentTime} onCreate={manageEntry} types={types}
                            customRender={(click, creating) => (
                                <Tooltip mouseEnterDelay={0.5} placement={"left"} title={<>
                                    {!currentSession && editableEntries.length < 1 && (getText('attendance.day_play') || 'začátek')}
                                    {!currentSession && editableEntries.length > 0 && (getText('attendance.day_resume') || 'pokračovat')}
                                    {currentSession && (getText('attendance.day_stop') || 'konec')}
                                </>}>
                                    <Button className={'ant-btn p-2'} shape={'circle'}
                                            style={{width: 120, height: 120, borderWidth: 2}}
                                            disabled={creating || loading || !!startOverlap || !report?._permissions?.['edit']}>
                                        <Row className={'flex-column h-100'} align={"middle"} justify={"center"}
                                             onClick={() => click(currentSession)}>
                                            <Col>{creating ?
                                                <LoadingOutlined style={{fontSize: 36}} className={'m-2'}/>
                                                : <div>
                                                    {!currentSession && (
                                                        <CaretRightOutlined className={'text-success'}
                                                                            style={{fontSize: 52, paddingLeft: 10.5}}/> //ugly but when centered it does not look good
                                                    )}
                                                    {currentSession && (
                                                        <div className={'bg-danger text-danger m-2'}
                                                             style={{height: 36, width: 36, borderRadius: 4}}/>
                                                    )}
                                                </div>
                                            }
                                            </Col>
                                        </Row>
                                    </Button>
                                </Tooltip>
                            )}/>
                    </Col>
                    <Col flex={'1 1'}>
                        {showPlanRequest && (
                            <Row justify={"space-between"} dir={''} className={'h-100 flex-column'}>
                                <Tooltip title={'Žádost o nepřítomnost'}>
                                    <Button onClick={() => this.createPlan()} className={'text-primary'}
                                            icon={<CalendarOutlined/>}
                                            shape={'circle'} size={"large"}/>
                                </Tooltip>
                                <Tooltip title={'Přidat poznámku'}>
                                    <Button onClick={() => this.createNote()}
                                            icon={<MessageOutlined/>}
                                            shape={'circle'} size={"large"}/>
                                </Tooltip>
                                {noteModal && report && <NoteModal date={date || moment()} report={report}
                                                                   onCancel={() => this.createNote(true)}
                                                                   onSave={note => this.createNote(true, note)}/>}
                            </Row>
                        )}
                    </Col>
                </Row>
                {startOverlap &&
                    <Alert type={'warning'}
                           message={'existuje záznam, který je právě teď aktuální'}/>}
                {!loading && !report?._permissions?.['edit'] &&
                    <Alert type={'warning'}
                           message={'Výkaz již není k dispozici pro úpravy'}/>}
                <CurrentPlans employee={employee} date={currentTime}/>
                <Row gutter={[36, 10]} className={'mt-2 w-100'} justify={"center"}>
                    <Col flex={'1 1'} className={'d-flex flex-column align-items-center justify-content-center'}>
                        <div>Příchod</div>
                        <Spin size={"small"} spinning={loading}>
                            {editableEntries[0] ?
                                MomentBuilder.build(editableEntries[0].startAt).format(DATE_FORMAT_HH_mm) : '- - : - -'}
                        </Spin>

                    </Col>
                    <Col className={'d-flex flex-column align-items-center justify-content-center'}>
                        <div>Odchod</div>
                        <Spin size={"small"} spinning={loading}>
                            {editableEntries[editableEntries.length - 1] ?
                                MomentBuilder.build(editableEntries[editableEntries.length - 1].endAt).format(DATE_FORMAT_HH_mm) : '- - : - -'}
                        </Spin>
                    </Col>
                    <Col flex={'1 1'} className={'d-flex flex-column align-items-center justify-content-center'}>
                        <div>Odpracováno</div>
                        <Spin size={"small"} spinning={loading}>
                            {editableEntries.length ? moment().startOf("d")
                                .add(this.getWorkMinutes(entries, currentTime), 'minutes')
                                .format(DATE_FORMAT_HH_mm) : '- - : - -'}
                        </Spin>
                    </Col>
                </Row>
            </Row>
        </div>

        return <div>
            {plan &&
                <RequestFormModal resource={plan} choices={[{value: employee.uuid, label: employee.fullName || ''}]}
                                  onCancel={this.cancelPlan} onSuccess={this.cancelPlan}
                                  employee={plan.employee as any}/>}
            {header}
        </div>
    }

    getGreeting(currentTime: moment.Moment, getText: (code: string) => string) {
        const hours = currentTime.hours()
        let greeting = getText('attendance.greeting_default')
        if (hours > 4 && hours < 10) {
            greeting = getText('attendance.greeting_morning')
        }
        if (hours > 17 && hours < 22) {
            greeting = getText('attendance.greeting_evening')
        }
        return greeting;
    }

    getWorkMinutes(entries: IEntry[], currentTime: moment.Moment) {
        return entries.reduce((t, a) => t + MomentBuilder.build(a.endAt || currentTime)
            .diff(MomentBuilder.build(a.startAt), 'm'), 0)
    }
}

const mapStateToProps = (state: IAppState) => {
    const {user} = state.setup

    return {
        user,
        getText: (code: string) => {
            const text = selectors.dictionary.getMessage(state, code)
            return text === code ? '' : text
        }
    }
}

export default connect(mapStateToProps)(Start)