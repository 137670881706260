import {Divider, Drawer, Modal, Radio, Row, Slider, Tooltip} from "antd";
import {
    BulbOutlined,
    ColumnHeightOutlined,
    FormatPainterOutlined,
    LineHeightOutlined,
    SaveOutlined,
    TableOutlined
} from "@ant-design/icons";
import React from "react";
import {connect} from "react-redux";
import IUser from "../../model/interface/security/IUser";
import Button from "../shared/button/Button";
import {onFontSizeChange, onMarginChange, onModeChange, onTableSizeChange} from "../../redux/actions/Theme";
import UsersService from "../../model/service/security/UsersService";
import {ITheme} from "../../model/interface/security/ITheme";
import {IAppState} from "../../redux/store";
import {Mode} from "../../redux/reducers/Theme";
import {LOCALSTORAGE_MODE_KEY} from "../../redux/constants/Theme";

interface IProps {
    user: IUser
    margin: string
    fontSize: string
    tableSize: string
    mode?: Mode
    onMarginChange: (margin: string) => void
    onFontSizeChange: (fontSize: string) => void
    onTableSizeChange: (fontSize: string) => void
    onModeChange: (mode: Mode) => void
    className: string
    toolTip?: boolean
    title?: boolean
}

interface IState {
    loading: boolean
    oldValues: ITheme | null
    open: boolean
}

class NavTheme extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            loading: false,
            oldValues: null,
            open: false
        }
    }

    static defaultProps = {
        title: true
    }

    componentWillUnmount() {
        this.setState({open: false})
    }

    changeMargin(margin: string) {
        this.props.onMarginChange(margin)
    }

    changeFontSize(fontSize: string) {
        this.props.onFontSizeChange(fontSize)
    }

    changeMode(mode: Mode) {
        this.props.onModeChange(mode)
    }

    changeTableSize(tableSize: string) {
        this.props.onTableSizeChange(tableSize)
    }

    save(): Promise<void> {
        const {fontSize, margin, tableSize, user, mode} = this.props
        this.setState({loading: true}, this.onOpen)
        return UsersService.resourceUpdate(user.id!, {theme: {fontSize, margin, tableSize, mode}}).then(
            () => new Promise<void>(resolve => {
                mode && localStorage.setItem(LOCALSTORAGE_MODE_KEY, mode);
                this.setState({loading: false, oldValues: null, open: false}, resolve)
            }))
    }

    close = () => {
        const {oldValues} = this.state
        const {margin, fontSize, tableSize, mode} = this.props
        if (oldValues && (
            oldValues!.margin !== margin
            || oldValues!.fontSize !== fontSize
            || oldValues!.tableSize !== tableSize
            || oldValues!.mode !== mode)) {
            Modal.confirm({
                content: 'Uložit změny?',
                okText: 'Ano',
                cancelText: 'Ne',
                onOk: () => this.save(),
                onCancel: () => {
                    this.props.onMarginChange(oldValues.margin)
                    this.props.onFontSizeChange(oldValues.fontSize)
                    this.props.onTableSizeChange(oldValues.tableSize)
                    this.props.onModeChange(oldValues.mode || 'light')
                    this.setState({open: false})
                }
            })
        } else {
            this.setState({open: false})
        }
    }

    onOpen() {
        const {margin, fontSize, tableSize, mode} = this.props
        this.setState({open: true, oldValues: {margin, fontSize, tableSize, mode}})
    }

    numberToSize(value: number): string {
        switch (value) {
            case(1):
                return 'xs';
            case(2):
                return 'sm';
            default:
            case(3):
                return 'md';
            case(4):
                return 'lg';
            case(5):
                return 'xl';
        }
    }

    sizeToNumber(size: string): number {
        switch (size) {
            case('xs'):
                return 1;
            case('sm'):
                return 2;
            default:
            case('md'):
                return 3;
            case('lg'):
                return 4;
            case('xl'):
                return 5;
        }
    }

    render() {
        const {toolTip, title, className, margin, fontSize, tableSize, mode} = this.props
        const {loading, open} = this.state

        return (
            <Tooltip title={toolTip ? "Přizpůsobit vzhled" : ''} placement={"bottom"}>
                <div onClick={() => this.onOpen()} className={className}>
                    <FormatPainterOutlined className="nav-icon mx-auto mr-3"/>
                    {title && <span>{"Přizpůsobit vzhled"}</span>}
                </div>
                <Drawer title={'Přizpůsobit vzhled'} onClose={this.close} destroyOnClose={true} visible={open} footer={
                    <Row justify={"space-between"}>
                        <Button loading={loading} icon={<SaveOutlined/>} type={"primary"}
                                onClick={() => this.save()}
                                className={'mr-2'}>
                            Uložit
                        </Button>
                        <Button type={"default"} onClick={() => this.close()}>Zavřít</Button>
                    </Row>
                }>
                    <div className="p-2">
                        <div>
                            <Divider><TableOutlined/> Rozložení tabulky</Divider>
                            <Slider min={1} max={4} step={1}
                                    defaultValue={this.sizeToNumber(tableSize)}
                                    onChange={(value: number) => this.changeTableSize(this.numberToSize(value))}
                            />
                        </div>

                        <div>
                            <Divider><ColumnHeightOutlined/> Mezery</Divider>

                            <Slider min={1} max={4} step={1}
                                    defaultValue={this.sizeToNumber(margin)}
                                    onChange={(value: number) => this.changeMargin(this.numberToSize(value))}
                            />
                        </div>

                        <div>
                            <Divider><LineHeightOutlined/> Velikost písma</Divider>

                            <Slider step={1} min={1} max={5}
                                    defaultValue={this.sizeToNumber(fontSize)}
                                    onChange={(value: number) => this.changeFontSize(this.numberToSize(value))}
                            />
                        </div>

                        <div>
                            <Divider><BulbOutlined/> Režim</Divider>
                            <Radio.Group className={'mb-3 d-flex w-100'} defaultValue={mode} buttonStyle="solid"
                                         onChange={e => this.changeMode(e.target.value)}>
                                <Radio.Button value="auto" className={"flex-grow-1"}>Auto</Radio.Button>
                                <Radio.Button value="light" className={"flex-grow-1"}>Světlý</Radio.Button>
                                <Radio.Button value="dark" className={"flex-grow-1"}>Tmavý</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                </Drawer>
            </Tooltip>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    const {margin, fontSize, tableSize, mode} = state.theme
    const {user} = state.setup
    return {
        margin, fontSize, tableSize, user, mode
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onMarginChange: (margin: string) => dispatch(onMarginChange(margin)),
        onFontSizeChange: (fontSize: string) => dispatch(onFontSizeChange(fontSize)),
        onTableSizeChange: (tableSize: string) => dispatch(onTableSizeChange(tableSize)),
        onModeChange: (mode: string) => dispatch(onModeChange(mode)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavTheme)
