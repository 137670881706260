import RestService from "model/service/dataStorage/RestService";
import IReport, {ReportState} from "../../../interface/company/workload/IReport";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import IRestFile from "../../../interface/api/IRestFile";
import {Moment} from "moment";
import {API_FILTER_TYPE} from "../../../constants/ApiConstant";
import {DATE_FORMAT_YYYY_MM_DD, DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../constants/DateConstant";

interface IRestReportsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IReport>
}

export type ReportOnSaveAction = 'fill_default' | 'delete_all_new' | 'check_collision'

interface IRestReportsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestReportsServiceCollectionResponse>,

    collectionListByEmployeeMonth(employee: string, month: Moment, options?: IRestServiceOptions): Promise<IRestReportsServiceCollectionResponse>,

    collectionCreate(data: any, onSave?: ReportOnSaveAction): Promise<IReport>,

    resourceRetrieve(id: number | string): Promise<IReport>,

    resourceUpdate(id: number | string, data: any, onSave?: ReportOnSaveAction): Promise<IReport>,

    resourceDelete(id: number | string): Promise<void>

    download(id: number | string): Promise<IRestFile>

    collectionUpdateState(ids: number[], state: ReportState): Promise<{success: boolean, count: number}>
}

const ReportsService_COLLECTION = 'company/workload/reports'
const ReportsService_DOWNLOAD = 'company/workload/reports-download/'
const UPDATE_STATES = 'company/workload/reports-change-state'

const ReportsService: IRestReportsService = {
    collectionList: function (options?) {
        return RestService.collectionList(ReportsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestReportsServiceCollectionResponse>
    },
    collectionListByEmployeeMonth: function (employee, month, options) {
        return RestService.collectionList(ReportsService_COLLECTION, {...options, filters: {
                employee: {
                    field: 'employee',
                    type: API_FILTER_TYPE.EQUAL,
                    value: employee
                },
                startAt: {
                    field: 'month',
                    type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                    value: month.clone().startOf('month').format(DATE_FORMAT_YYYY_MM_DD) + ' 00:00:00'
                },
                endAt: {
                    field: 'month',
                    type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                    value: month.clone().endOf('month').format(DATE_FORMAT_YYYY_MM_DD) + ' 23:59:59'
                }
            }}) as Promise<IRestReportsServiceCollectionResponse>
    },
    collectionCreate: function (data, onSave) {
        return RestService.collectionCreate(ReportsService_COLLECTION, {...data, onSave}) as Promise<IReport>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(ReportsService_COLLECTION, id) as Promise<IReport>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(ReportsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data, onSave) {
        return RestService.resourceUpdate(ReportsService_COLLECTION, id, {...data, onSave}) as Promise<IReport>
    },
    download: function (id) {
        return RestService.resourceDownload(ReportsService_DOWNLOAD + id)
    },
    collectionUpdateState(ids: number[], state: ReportState): Promise<{ success: boolean; count: number }> {
        return RestService.resourceUpdate(UPDATE_STATES, state, {ids}) as Promise<{success: boolean, count: number}>
    }
}

export default ReportsService