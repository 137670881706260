import React from "react";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import IReportWidgetMenu from "../../../../../model/interface/report/IReportWidgetMenu";
import {Card, Col, Menu, Row} from "antd";
import MenuGroupBuilder from "../../menu/builder/MenuGroupBuilder";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../../redux/reducers/Setup";
import IMenu from "../../../../../model/interface/ui/IMenu";
import MenuItemLink from "../../menu/builder/MenuItemLink";
import {getIconLibrary} from "../../../../../utils/IconBuilder";

interface IProps extends IReportWidgetProps<IReportWidgetMenu> {
    menus: IMenu[]
}

class MenuWidget extends React.Component<IProps> {
    render() {
        const {options, menus, history} = this.props
        const {menu, layout} = options
        const menuObject = menus.find(m => m.uuid === menu)
        let {bordered} = options
        if(typeof bordered == 'undefined') {
            bordered = true
        }
        return menuObject && layout ? ({
            column: <div className={bordered && 'rounded border overflow-hidden bg-theme'}>
                <Menu
                    theme="light"
                    mode="inline"
                    style={{height: "100%", borderRight: 0, background: "transparent"}}
                >
                    {menuObject && menuObject.items.length > 0 && (
                        MenuGroupBuilder.buildChildren(menuObject.items, null, () => {
                        }, history)
                    )}
                </Menu>
            </div>,
            row: <div className={bordered && 'rounded border overflow-hidden bg-theme'}>
                <Row>
                    {menuObject && menuObject.items.length > 0 && (
                        menuObject.items.filter(value => !value.parent).map(item => {
                            const iconProps = {
                                ion: {
                                    height: '28px',
                                    width: '28px',
                                    style: {margin: 0},
                                },
                                ant: {
                                    style: {fontSize: '28px'},
                                    className: 'm-0'
                                },
                            }[getIconLibrary(item.icon || '')]
                            return (
                                <Col className={'p-2 w-250 mw-100'}>
                                    <Card className={'m-0 w-100 overflow-hidden h-100'}
                                          bodyStyle={{padding: 0, height: '100%'}}>
                                        <Menu className={'border-0 h-100 w-100'}>
                                            <MenuItemLink
                                                doNotRefParent={true}
                                                className={'p-2 menu-widget h-100 lh-normal text-center text-truncate m-0 d-flex align-items-center justify-content-center'}
                                                item={item}
                                                iconProps={iconProps}
                                                ellipsisTooltipPosition={'top'}
                                                onItemCLick={() => {
                                                }} items={menuObject.items}
                                                customRender={(item, icon) =>
                                                    <span>
                                                        {icon && <div className={"mb-2"}>{icon}</div>}
                                                        {item.title}
                                                    </span>
                                                }/>
                                        </Menu>
                                    </Card>
                                </Col>
                            )
                        })
                    )}
                </Row>

            </div>
        }[layout]) : ''
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {menu} = state.setup as ISetupState

    return {
        menus: menu
    }
}

export default connect(mapStateToProps)(MenuWidget)